import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ProductPrice from 'Component/ProductPrice';
import { ProductType } from 'Type/ProductList.type';

/** @namespace Pwa/Component/ProductCardDetails/Elements/ProductPrice/Component */
export class ProductPriceComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        reverse: PropTypes.bool,
    };

    static defaultProps = {
        reverse: false,
    };

    render() {
        const {
            reverse,
            product: { price_range, categories },
        } = this.props;

        return (
            <div block="ProductCardDetails" elem="PriceWrapper">
                <ProductPrice
                  price={ price_range }
                  reverse={ reverse }
                  categories={ categories }
                  promoPosition="bottom_primolivello"
                  mix={ { block: 'ProductCard', elem: 'Price' } }
                />
            </div>
        );
    }
}

export default ProductPriceComponent;
