import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import AddToCart from 'Component/AddToCart';
import NoPropagationEvent from 'Component/NoPropagateEvents';
import { BOOK_POPUP_BEDROOM, BOOK_POPUP_KITCHEN } from 'Component/ProductActions/ProductActions.config';
import { showPopup } from 'Store/Popup/Popup.action';
import { ProductType } from 'Type/ProductList.type';

/** @namespace Pwa/Component/ProductCardDetails/Elements/ProductActionButton/Component */
export class ProductActionButtonComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        isDisplayMore: PropTypes.bool,
        mix: PropTypes.object,
        showPopup: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isDisplayMore: false,
        mix: {},
    };

    renderAddToCart() {
        const quantity = 1;

        const {
            product,
            mix,
        } = this.props;

        if (product.mc_black_friday_counter !== undefined && product.mc_black_friday_counter !== null) {
            if (product.mc_black_friday_counter.items_remaining <= 0 && product.mc_black_friday_counter.items_remaining !== null) {
                return (
                    <button disabled className="Button AddToCart-Disabled ProductActions-AddToCart">
                        <span>{ __('Add to cart') }</span>
                    </button>
                );
            }
        }

        return (
            <NoPropagationEvent mix={ { mix } }>
                <AddToCart
                  configurableVariantIndex={ -1 }
                  product={ product }
                  mix={ { block: 'ProductActions', elem: 'AddToCart' } }
                  quantity={ quantity }
                  groupedProductQuantity={ { } }
                  productOptionsData={ { } }
                />
            </NoPropagationEvent>
        );
    }

    render() {
        const {
            product: { mc_vendibile_sito, attribute_set }, isDisplayMore, mix, showPopup,
        } = this.props;

        if (mc_vendibile_sito === 1 && !isDisplayMore) {
            return this.renderAddToCart();
        }

        const BOOK_POPUP = attribute_set?.includes('Cucine') ? BOOK_POPUP_KITCHEN : BOOK_POPUP_BEDROOM;

        return (
            <NoPropagationEvent mix={ { mix } }>
                <button
                  className="Button"
                  block="ProductCard"
                  elem="CTA"
                  onClick={ () => {
                      showPopup(BOOK_POPUP);
                  } }
                >
                    <span>
                        { __('Prenota un appuntamento') }
                    </span>
                </button>
            </NoPropagationEvent>
        );
    }
}

/** @namespace Pwa/Component/ProductCardDetails/Elements/ProductActionButton/Component/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (id, payload) => dispatch(showPopup(id, payload)),
});

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(null, mapDispatchToProps)(ProductActionButtonComponent);
