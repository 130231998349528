import PropTypes from 'prop-types';
import SwiperCore, { Navigation } from 'swiper/core';

import Image from 'Component/Image';
import ImageSticker from 'Component/ImageSticker';
import Loader from 'Component/Loader';
import PopupSuspense from 'Component/PopupSuspense';
import { ProductType } from 'Component/Product/Product.config';
import { FILTER_TAG_PRODUCT_CARD_PAGE_SIZE } from 'Component/ProductCard/ProductCard.config';
import ProductCardDetails from 'Component/ProductCardDetails';
import ProductPrice from 'Component/ProductPrice';
import PromoMask from 'Component/PromoMask';
import TextPlaceholder from 'Component/TextPlaceholder';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import { ProductCardComponent as SourceProductCard } from 'SourceComponent/ProductCard/ProductCard.component';

import 'swiper/components/navigation/navigation.scss';
import './ProductCard.override.style';
import 'swiper/swiper.scss';

SwiperCore.use([Navigation]);

/** @namespace Pwa/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCard {
    static propTypes = {
        ...SourceProductCard.props,
        setTotalItemsSubCategoryFiltered: PropTypes.func.isRequired,
        startPage: PropTypes.number,
        endPage: PropTypes.number,
        totalItems: PropTypes.number,
    };

    static defaultProps = {
        ...SourceProductCard.defaultProps,
        endPage: FILTER_TAG_PRODUCT_CARD_PAGE_SIZE,
        startPage: 0,
    };

    __construct(props) {
        super.__construct?.(props);
        this.state = {
            categoryUrls: [],
            filteredTagsProductCount: 0,
        };
        localStorage.removeItem('displayed_product_card_count');
        localStorage.removeItem('total_product_card_count');
    }

    renderProductActions() {
        return false;
    }

    renderImageAttribute() {
        const {
            attribute: {
                attribute_label,
                attribute_value,
            },
        } = this.props;

        if (!attribute_value || attribute_value === 'no_selection') {
            return this.renderTextPlaceholder();
        }

        return (
            <img
              block="ProductAttributeValue"
              elem="MediaImage"
              src={ `/media/catalog/product${attribute_value}` }
              alt={ attribute_label }
            />
        );
    }

    renderSconto(image) {
        return image && (
            <img
              block="ProductCard"
              elem="Sconto"
              src={ `${image}` }
              alt=""
            />
        );
    }

    renderOverlayFallback() {
        const { product: { sku } } = this.props;

        return (
            <PopupSuspense
              actualOverlayKey={ sku }
            />
        );
    }

    renderProductPrice() {
        const {
            product: { price_range, type_id, categories },
            isConfigurableProductOutOfStock,
            isBundleProductOutOfStock,
        } = this.props;

        if (!price_range) {
            return <TextPlaceholder />;
        }

        switch (type_id) {
        case ProductType.CONFIGURABLE:
            if (isConfigurableProductOutOfStock()) {
                return this.renderEmptyProductPrice();
            }
            break;
        case ProductType.BUNDLE:
            if (isBundleProductOutOfStock()) {
                return this.renderEmptyProductPrice();
            }
            break;
        default:
            break;
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                { this.renderTierPrice() }
                { this.renderProductTypePriceBadge() }
                <ProductPrice
                  price={ price_range }
                  categories={ categories }
                  reverse
                  mix={ { block: 'ProductCard', elem: 'Price' } }
                />
            </div>
        );
    }

    registerSharedElement = () => {
        const {
            registerSharedElement, product, category: { name }, position, pushProductClick,
        } = this.props;

        registerSharedElement(this.imageRef);

        pushProductClick(product, position, name);
    };

    renderDiscountTag() {
        const { product: { mc_flashsale: { discount_image = {} } = {} } = {}, device } = this.props;
        const { standard, xs } = discount_image || {};
        const image = (device.isMobile) ? xs : standard;

        return image && (
            <div block="ImageSticker" mods={ { Position: 'TOPRIGHT', size: 'small' } }>
                <img
                  block="ImageSticker"
                  elem="CustomDiscount"
                  src={ `${image}` }
                  alt="Promo"
                />
            </div>
        );
    }

    renderPictureHover() {
        const { product } = this.props;

        if (!product.mc_product_listing_images) {
            return null;
        }

        const { id, name, mc_product_listing_images: { hover } } = product;

        return (
            <Image
              imageRef={ this.imageRef }
              src={ hover }
              alt={ name }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture', mods: 'hasImageHover' } }
              isPlaceholder={ !id }
            />
        );
    }

    renderPicture(mix = {}) {
        const { product: { id, name, mc_product_listing_images }, device: { isMobile } } = this.props;

        if (!mc_product_listing_images) {
            return null;
        }

        const { mobile, main } = mc_product_listing_images;
        const image = isMobile ? mobile : main;

        this.sharedComponent = (
            <Image
              imageRef={ this.imageRef }
              src={ image }
              alt={ name }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture', mix } }
              isPlaceholder={ !id }
            />
        );

        return (
            <>
                { this.sharedComponent }
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ image }
                />
            </>
        );
    }

    renderCardLinkWrapper(children, mix = {}) {
        const { product: { url } } = this.props;

        if (!url) {
            return (
                <div
                  block="ProductCard"
                  elem="Link"
                  mods={ { loading: true } }
                />
            );
        }

        return super.renderCardLinkWrapper(children, mix);
    }

    renderCardListContent() {
        const {
            product,
            renderContent,
            product: {
                mc_hover_image,
                mc_flashsale: { is_coming = false, time_start } = {},
                categories,
            },
            layout,
            promo,
            changeColor,
        } = this.props;

        const hasImageHover = mc_hover_image !== '';

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        if (is_coming) {
            return (
                <PromoMask reveal_time={ time_start } />
            );
        }

        return (
            <>
                { this.renderCardLinkWrapper(
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure" mods={ { hasImageHover } }>
                            { this.renderPicture() }
                        </figure>
                        <figure block="ProductCard" elem="Figure">
                            { hasImageHover && this.renderPictureHover() }
                        </figure>
                        { categories
                            && categories.map((category, idx) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <ImageSticker tag={ category } key={ idx } />
                            )) }
                        { this.renderDiscountTag() }

                    </div>
                ) }
                { Object.keys(product).length > 0 && (
                    <ProductCardDetails
                      layout={ layout }
                      promo={ promo }
                      product={ product }
                      colorChanger={ changeColor }
                    />
                ) }
            </>
        );
    }

    render() {
        const {
            children,
            mix,
            isLoading,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions,
            layout,
            promo,
            product: { url, categories },
            subCategorySelected,
        } = this.props;

        const mods = {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions,
            promo,
            loading: !url,
        };

        const { categoryUrls } = this.state;

        if (categoryUrls.length === 0 && categories !== undefined && categories !== null) {
            const newCategoryUrls = [];
            categories.forEach((cat) => {
                newCategoryUrls.push(cat.url);
            });
            this.setState({ categoryUrls: newCategoryUrls });
        }

        /* selected categories */
        if ((categoryUrls.length > 0 && subCategorySelected !== undefined && subCategorySelected !== null
            && subCategorySelected !== '' && typeof subCategorySelected === 'string')) {
            // localStorage.setItem('total_not_filtered_product_card_count', (countProducts));
            /**/
            // let displayedProductCardCount = parseInt(localStorage.getItem('displayed_product_card_count'), 10);

            /**/
            if (categoryUrls.includes(subCategorySelected)) {
                // localStorage.setItem('total_product_card_count', (totalProductcardCount));

                // displayedProductCardCount += 1;
                // localStorage.setItem('displayed_product_card_count', (displayedProductCardCount));

                if (layout !== CategoryPageLayout.GRID) {
                    return (
                            <li
                              block="ProductCard"
                              mods={ mods }
                              mix={ mix }
                            >
                                <Loader isLoading={ isLoading } />
                                { this.renderCardListContent() }
                            </li>
                    );
                }

                return (
                        <li
                          block="ProductCard"
                          mods={ mods }
                          mix={ mix }
                        >
                            <Loader isLoading={ isLoading } />
                            { this.renderCardContent() }
                            { this.renderProductActions() }
                            <div block="ProductCard" elem="AdditionalContent">
                                { children }
                            </div>
                        </li>
                );
            }
        }

        /* all rendered */
        if ((subCategorySelected === ''
            || typeof subCategorySelected !== 'string')) {
            if (layout !== CategoryPageLayout.GRID) {
                return (
                    <li
                      block="ProductCard"
                      mods={ mods }
                      mix={ mix }
                    >
                        <Loader isLoading={ isLoading } />
                        { this.renderCardListContent() }
                    </li>
                );
            }

            return (
                <li
                  block="ProductCard"
                  mods={ mods }
                  mix={ mix }
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderCardContent() }
                    { this.renderProductActions() }
                    <div block="ProductCard" elem="AdditionalContent">
                        { children }
                    </div>
                </li>
            );
        }

        return null;
    }
}

export default ProductCardComponent;
