import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import BlackFridayCounter from 'Component/BlackFridayCounter/BlackFridayCounter.component';
import FlashSaleIndicator from 'Component/FlashSalesIndicator';
import ProductActionButton from 'Component/ProductCardDetails/Elements/ProductActionButton.component';
import ProductCertification from 'Component/ProductCardDetails/Elements/ProductCertification.component';
import ProductComposizioneTipo from 'Component/ProductCardDetails/Elements/ProductComposizioneTipo.component';
import ProductDescription from 'Component/ProductCardDetails/Elements/ProductDescription.component';
import ProductDimensions from 'Component/ProductCardDetails/Elements/ProductDimensions.component';
import ProductFinish from 'Component/ProductCardDetails/Elements/ProductFinish.component';
import ProductLink from 'Component/ProductCardDetails/Elements/ProductLink.component';
import ProductName from 'Component/ProductCardDetails/Elements/ProductName.component';
import ProductPrice from 'Component/ProductCardDetails/Elements/ProductPrice.component';
import { ProductType } from 'Type/ProductList.type';

/** @namespace Pwa/Component/ProductCardDetails/Layout/PromoActive/Component */
export class PromoActiveComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        hasCartButton: PropTypes.bool,
        promo: PropTypes.string.isRequired,
        countdown: PropTypes.shape({
            days: PropTypes.number,
            hours: PropTypes.number,
            minutes: PropTypes.number,
            seconds: PropTypes.number,
        }).isRequired,
    };

    static defaultProps = {
        hasCartButton: true,
    };

    render() {
        const {
            product,
            product: {
                sku,
                mc_color_information: { description },
                mc_flashsale,
                mc_black_friday_counter,
            },
            promo,
            hasCartButton,
            countdown,
        } = this.props;

        const { items_total = 0 } = mc_flashsale || { };
        const showCountdown = (items_total > -1);

        const { items_remaining = 0 } = mc_black_friday_counter || { };
        const showCountdown_BF = (items_remaining > -1);
        const promoActionWrapper = showCountdown_BF ? 'PromoActionWrapperBF' : 'PromoActionWrapperBF';

        return (
            <ProductLink
              product={ product }
              mix={ {
                  block: 'ProductCardDetails',
                  mods: {
                      single: true, column: true, promo: true, [promo]: true,
                  },
              } }
            >
                <div block="ProductCardDetails" elem="ContentWrapper">
                    <div block="ProductCardDetails" elem="Content">
                        <div block="ProductCardDetails" elem="MainInfo">
                            <ProductName product={ product } />

                            { description?.trim() && (
                                <>
                                <ProductDescription product={ product } />
                                <ProductFinish product={ product } />
                                </>
                            ) }
                            { !description?.trim() && (
                                <>
                                <ProductFinish product={ product } />
                                <ProductDescription product={ product } />
                                </>
                            ) }

                        </div>

                        <div block="ProductCardDetails" elem="FeaturesList" />

                        <div block="ProductCardDetails" elem="PriceContainer">
                            <ProductPrice promoPosition="top-left" product={ product } reverse />
                            <ProductDimensions product={ product } />
                            <ProductComposizioneTipo product={ product } />
                            <ProductCertification product={ product } size="small" />

                        </div>
                    </div>

                    <div block="ProductCardDetails" elem={ promoActionWrapper }>
                        { showCountdown && (
                            <div>
                                <FlashSaleIndicator
                                  flashsale={ mc_flashsale }
                                  sku={ sku }
                                  isInfoIcon={ false }
                                />
                            </div>
                        ) }
                        { showCountdown_BF && (
                            <div>
                                <BlackFridayCounter
                                  blackFriday={ mc_black_friday_counter }
                                  sku={ sku }
                                  isInfoIcon={ false }
                                  countdown={ countdown }
                                  isCompactVersion={ false }
                                />
                            </div>
                        ) }
                        { hasCartButton
                        && (
                            <ProductActionButton
                              product={ product }
                              mix={ { block: 'ProductCardDetails', elem: 'PromoAction' } }
                            />
                        ) }
                    </div>
                </div>
            </ProductLink>
        );
    }
}

export default PromoActiveComponent;
