import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ChildrenType } from 'Type/Common.type';
import { ProductType } from 'Type/ProductList.type';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace Pwa/Component/ProductCardDetails/Elements/ProductLink/Component */
export class ProductLinkComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        children: ChildrenType,
        mix: PropTypes.object,
    };

    static defaultProps = {
        children: [],
        mix: {},
    };

    handlerClick = (e) => {
        const { product: { url } } = this.props;
        e.stopPropagation();
        history.push({ pathname: appendWithStoreCode(url) });
    };

    render() {
        const { children, mix } = this.props;

        return (
            <div
              block="ProductCardDetails"
              elem="LinkWrapper"
              mix={ mix }
              role="button"
              tabIndex="0"
              onClick={ this.handlerClick }
              onKeyDown={ this.handlerClick }
            >
                    { children }
            </div>
        );
    }
}

export default ProductLinkComponent;
