import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ProductCardDetailsMultiPrice from 'Component/ProductCardDetails/Layout/MultiPrice.component';
import ProductCardDetailsMultiProductsRows
    from 'Component/ProductCardDetails/Layout/MultiProductsRows.component';
import MultiProductsSliderMobile
    from 'Component/ProductCardDetails/Layout/MultiProductsSliderMobile.component';
import ProductCardDetailsPromoActive from 'Component/ProductCardDetails/Layout/PromoActive.component';
import ProductCardDetailsPromoClosed from 'Component/ProductCardDetails/Layout/PromoClosed.component';
import ProductCardDetailsSingle from 'Component/ProductCardDetails/Layout/Single.component';
import ProductCardDetailsSingleColumn from 'Component/ProductCardDetails/Layout/SingleColumn.component';
import ProductCardDetailsSingleWithoutColor from 'Component/ProductCardDetails/Layout/SingleWithoutColor.component';
import { ONE_COLUMN_LAYOUT, THREE_COLUMN_LAYOUT, TWO_COLUMN_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { ProductType } from 'Type/ProductList.type';
import { startMobile } from 'Util/mcMobileCheck';

import './ProductCardDetails.style';

/** @namespace Pwa/Component/ProductCardDetails/Component */
export class ProductCardDetailsComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        promo: PropTypes.string,
        colorChanger: PropTypes.func.isRequired,
        layout: PropTypes.oneOf([
            ONE_COLUMN_LAYOUT,
            TWO_COLUMN_LAYOUT,
            THREE_COLUMN_LAYOUT,
        ]),
        countdown: PropTypes.shape({
            days: PropTypes.number,
            hours: PropTypes.number,
            minutes: PropTypes.number,
            seconds: PropTypes.number,
        }).isRequired,
    };

    static defaultProps = {
        layout: ONE_COLUMN_LAYOUT,
        promo: null,
    };

    render() {
        const {
            product,
            product: {
                mc_multiprice,
                mc_multiprice_title,
                mc_etichetta_colore,
                mc_flashsale,
                mc_black_friday_counter,
            },
            layout,
            promo,
            colorChanger,
            countdown,
        } = this.props;

        const is_active_BF = (mc_black_friday_counter?.is_closed === false);
        const hasMultiPrice = mc_multiprice?.length > 0;
        const isMobile = startMobile();
        const isMulticolumnLayout = !promo && [THREE_COLUMN_LAYOUT, TWO_COLUMN_LAYOUT].includes(layout);

        if (is_active_BF || promo) {
            if (mc_flashsale?.is_closed && mc_black_friday_counter?.is_closed) {
                return (
                    <ProductCardDetailsPromoClosed product={ product } promo={ promo } />
                );
            }

            return (
                <ProductCardDetailsPromoActive product={ product } promo={ promo } countdown={ countdown } />
            );
        }

        if (isMobile || isMulticolumnLayout) {
            if (hasMultiPrice) {
                return (
                        <MultiProductsSliderMobile
                          layout={ layout }
                          product={ product }
                          colorChanger={ colorChanger }
                        />
                );
            }

            return (
                <ProductCardDetailsSingleColumn
                  product={ product }
                  colorChanger={ colorChanger }
                />
            );
        }

        if (hasMultiPrice) {
            if (mc_multiprice_title) {
                return (<ProductCardDetailsMultiPrice product={ product } colorChanger={ colorChanger } />);
            }

            return (<ProductCardDetailsMultiProductsRows product={ product } colorChanger={ colorChanger } />);
        }

        if (mc_etichetta_colore) {
            return (<ProductCardDetailsSingle product={ product } colorChanger={ colorChanger } />);
        }

        return (<ProductCardDetailsSingleWithoutColor product={ product } />);
    }
}

export default ProductCardDetailsComponent;
