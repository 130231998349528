import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Provider, Subscribe } from 'unstated';

import { ProductType } from 'Component/Product/Product.config';
import SharedTransitionContainer from 'Component/SharedTransition/SharedTransition.unstated';
import ProductListQuery from 'Query/ProductList.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as SourceProductCardContainer,
} from 'SourceComponent/ProductCard/ProductCard.container';
import { showNotification } from 'Store/Notification/Notification.action';
import {
    setTotalItemsSubCategoryFiltered,
} from 'Store/TotalItemsSubCategoryFiltered/TotalItemsSubCategoryFiltered.action';
import history from 'Util/History';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request/Request';
import { objectToUri } from 'Util/Url';

import ProductCard from './ProductCard.component';

export const GTMDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/GTM/GTM.dispatcher'
);

/** @namespace Pwa/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    category: state.CategoryReducer.category,
    subCategorySelected: state.SubCategoryFilterTagsReducer.subCategorySelected,
    totalItemsSubCategoryFiltered: state.TotalItemsSubCategoryFilteredReducer.totalItemsSubCategoryFiltered,
    pageStartSubCategoryFiltered: state.TotalItemsSubCategoryFilteredReducer.startPageSubCategoryFiltered,
    pageEndSubCategoryFiltered: state.TotalItemsSubCategoryFilteredReducer.endPageSubCategoryFiltered,
    totalItems: state.ProductListReducer.totalItems,
});

/** @namespace Pwa/Component/ProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    pushProductClick: (product, position, list) => GTMDispatcher.then(
        ({ default: dispatcher }) => dispatcher.pushProductClick(dispatch, product, position, list)
    ),
    setTotalItemsFiltered: (total) => dispatch(setTotalItemsSubCategoryFiltered(total)),
});

/** @namespace Pwa/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    static propTypes = {
        ...SourceProductCardContainer.propTypes,
        subCategorySelected: PropTypes.string,
        totalItems: PropTypes.number.isRequired,

    };

    static defaultProps = {
        ...SourceProductCardContainer.defaultProps,
        subCategorySelected: '',
    };

    __construct(props) {
        super.__construct(props);
        this.state = {
            ...this.state,
            colorVariant: {},
        };
    }

    containerProps() {
        const {
            subCategorySelected,
        } = this.props;

        return {
            ...super.containerProps(),
            subCategorySelected,
        };
    }

    _getLinkTo() {
        const { product: realProduct } = this.props;
        const { colorVariant } = this.state;
        const product = (Object.keys(colorVariant).length === 0) ? realProduct : colorVariant;
        const { url } = product;
        const destination = { url };

        if (!url) {
            return undefined;
        }

        const { parameters } = this._getConfigurableParameters();
        const { state: { category = null } = {} } = history.location;

        return {
            pathname: destination.url,
            state: { product, prevCategoryId: category },
            search: objectToUri(parameters),
        };
    }

    _getProductOrVariant() {
        const { product: { type_id, variants }, product: realProduct } = this.props;
        const { colorVariant } = this.state;

        const product = (Object.keys(colorVariant).length === 0) ? realProduct : colorVariant;

        if (type_id === ProductType.CONFIGURABLE && variants?.length) {
            return variants[this._getCurrentVariantIndex()] || product || {};
        }

        return product || {};
    }

    _getThumbnail() {
        const product = this._getProductOrVariant();
        const { image: { url } = {} } = product;

        if (this._isThumbnailAvailable(url)) {
            return url;
        }

        // If thumbnail is, missing we try to get image from parent
        const { product: { image: { url: parentUrl } = {} } } = this.props;

        if (this._isThumbnailAvailable(parentUrl)) {
            return parentUrl;
        }

        return '';
    }

    changeColor(productSKU) {
        this.setState({ isLoading: true });
        const options = {
            args: {
                filter: {
                    productSKU,
                },

            },
        };

        const query = [ProductListQuery.getQuery(options)];
        executeGet(prepareQuery(query), 'ProductColor', 0)
            .then(
                /** @namespace Pwa/Component/ProductCard/Container/ProductCardContainer/changeColor/then/catch/executeGet/then */
                ({ products: { items } }) => {
                    const colorVariant = items[0] || {};
                    this.setState({
                        colorVariant,
                        isLoading: false,
                    });
                }
            )
            .catch(
                /** @namespace Pwa/Component/ProductCard/Container/ProductCardContainer/changeColor/then/catch */
                (e) => {
                    this.setState({ isLoading: false });
                    showNotification('error', __('Error fetching Color Variant!'), e);
                }
            );
    }

    containerFunctions = {
        ...this.containerFunctions,
        changeColor: this.changeColor.bind(this),
        setTotalItemsSubCategoryFiltered: this.localSetTotalItemsSubCategoryFiltered.bind(this),
    };

    localSetTotalItemsSubCategoryFiltered(total) {
        const { setTotalItemsFiltered } = this.props;
        setTotalItemsFiltered(total);
    }

    render() {
        const {
            product: productProps = {},
            pushProductClick,
            pageStartSubCategoryFiltered,
            pageEndSubCategoryFiltered,
            totalItems,
            ...props
        } = this.props;

        const { colorVariant, isLoading } = this.state;
        const product = (Object.keys(colorVariant).length === 0) ? productProps : colorVariant;

        return (
            <Provider>
                <Subscribe to={ [SharedTransitionContainer] }>
                    { ({ registerSharedElement }) => (
                        <ProductCard
                          { ...this.containerFunctions }
                          { ...this.containerProps() }
                          startPage={ pageStartSubCategoryFiltered }
                          endPage={ pageEndSubCategoryFiltered }
                          totalItems={ totalItems }
                          { ...{
                              ...props, isLoading, product, registerSharedElement, pushProductClick,
                          } }
                        />
                    ) }
                </Subscribe>
            </Provider>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
