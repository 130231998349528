import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import FlashSaleIndicator from 'Component/FlashSalesIndicator';
import ProductActionButton from 'Component/ProductCardDetails/Elements/ProductActionButton.component';
import ProductCertification from 'Component/ProductCardDetails/Elements/ProductCertification.component';
import ProductColors from 'Component/ProductCardDetails/Elements/ProductColors.component';
import ProductComposizioneTipo from 'Component/ProductCardDetails/Elements/ProductComposizioneTipo.component';
import ProductDescription from 'Component/ProductCardDetails/Elements/ProductDescription.component';
import ProductDimensions from 'Component/ProductCardDetails/Elements/ProductDimensions.component';
import ProductFinish from 'Component/ProductCardDetails/Elements/ProductFinish.component';
import ProductLink from 'Component/ProductCardDetails/Elements/ProductLink.component';
import ProductName from 'Component/ProductCardDetails/Elements/ProductName.component';
import ProductPrice from 'Component/ProductCardDetails/Elements/ProductPrice.component';
import { MATERASSI_VARIANTI } from 'Component/ProductCardDetails/ProductCardDetails.config';
import { ProductType } from 'Type/ProductList.type';

import ProductIconsComponent from '../Elements/ProductIcons.component';

/** @namespace Pwa/Component/ProductCardDetails/Layout/Single/Component */
export class SingleComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        colorChanger: PropTypes.func.isRequired,
        isDisabled: PropTypes.bool,
        layout: PropTypes.string.isRequired,
    };

    static defaultProps = {
        isDisabled: false,
    };

    isIncludesCategory = () => {
        const { pathname } = window.location;
        const routes = [
            'cucine',
            'camere',
            'soggiorni',
            'tavoli',
            'bagni',
            'letti',
            'divani',
            'cocinas',
            'dormitorios',
            'salones',
            'mesas',
            'banos',
            'camas',
            'sofas',
        ];

        // Verifico se nel pathname è inclusa una delle parole presenti in routes
        return routes.some((element) => {
            if (pathname.includes(element)) {
                return true;
            }

            return window.location.pathname.indexOf('/reti-e-materassi') === -1
            && window.location.pathname.indexOf('/somieres-y-colchones') === -1;
        });
    };

    render() {
        const {
            product,
            product: {
                sku, mc_etichetta_colore, mc_flashsale, nuovo_template_option,
            },
            colorChanger,
            isDisabled,
            layout,
        } = this.props;

        const { items_total = 0 } = mc_flashsale || {};
        const showCountdown = (items_total > 0);
        const showColor = (mc_etichetta_colore && !showCountdown);
        const isVariant = (mc_etichetta_colore === MATERASSI_VARIANTI);

        return (
            <ProductLink
              product={ product }
              mix={ { block: 'ProductCardDetails', mods: { single: true, inline: true } } }
            >
                <div block="ProductCardDetails" elem="ContentWrapper">
                    <div block="ProductCardDetails" elem="Content">
                        <div block="ProductCardDetails" elem="SingleColumn" mods={ { isLeft: true } }>
                            <div block="ProductCardDetails" elem="MainInfo">
                                <div className="name-cosmog">
                                    <ProductName product={ product } />
                                </div>
                                <ProductDescription product={ product } />
                                <ProductDimensions product={ product } />
                            </div>
                            <div block="ProductCardDetails" elem="FeaturesList">
                                { this.isIncludesCategory()
                                    && (
                                        <ProductCertification product={ product } size="medium" />
                                    ) }
                                <ProductIconsComponent product={ product } />

                                { nuovo_template_option && nuovo_template_option.label !== 'template_materassi'
                                    && (<ProductFinish product={ product } />) }
                            </div>
                        </div>
                        <div block="ProductCardDetails" elem="SingleColumn" mods={ { isRight: true } }>
                            <div block="ProductCardDetails" elem="ColorWrapper">
                               { (window.location.pathname === '/reti-e-materassi.html'
                                || window.location.pathname === '/somieres-y-colchones.html')
                                && (
                                 <ProductCertification product={ product } size="medium" />
                                ) }
                                { showCountdown && (
                                    <div>
                                        <FlashSaleIndicator
                                          flashsale={ mc_flashsale }
                                          sku={ sku }
                                          isInfoIcon={ false }
                                        />
                                    </div>
                                ) }
                                { showColor && (
                                    <ProductColors
                                      product={ product }
                                      colorChanger={ colorChanger }
                                      isDisabled={ isDisabled }
                                      isVariant={ isVariant }
                                      layout={ layout }
                                    />
                                ) }
                            </div>
                            <div block="ProductCardDetails" elem="PriceContainer">
                                <ProductPrice promoPosition="top_column" product={ product } reverse />
                                <ProductComposizioneTipo product={ product } />
                            </div>
                        </div>
                        <div block="ProductCardDetails" elem="ActionWrapper">
                            <ProductActionButton product={ product } />
                        </div>
                    </div>
                </div>
            </ProductLink>
        );
    }
}

export default SingleComponent;
