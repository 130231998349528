import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Link from 'Component/Link';
import { ChildrenType } from 'Type/Common.type';
import { ProductType } from 'Type/ProductList.type';

import { createSecondLevelSlug } from '../../util/secondLevelSlug';

/** @namespace Pwa/Component/SecondaryLink/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace Pwa/Component/SecondaryLink/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Pwa/Component/SecondaryLink/Container */
export class SecondaryLinkContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        children: ChildrenType.isRequired,
        location: PropTypes.any.isRequired,
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    renderSecondaryLink = () => {
        const { product: { sku, name }, children } = this.props;

        return (
            <Link to={ createSecondLevelSlug(sku, name) }>{ children }</Link>
        );
    };

    render() {
        const { product: { secondolivello, url }, children } = this.props;
        const { pathname } = window.location;
        const isDivani = pathname.includes(__('divani'));
        const isLetti = pathname.includes(__('letti2'));
        const isBagni = pathname.includes(__('bagni'));
        const isSedie = pathname.includes(__('sedie'));
        const isTavoli = pathname.includes(__('tavoli'));
        const isSoggiorni = pathname.includes(__('soggiorni'));
        const isMaterassi = pathname.includes(__('materassi'));
        const isCamere = pathname.includes(__('camere'));
        const isCucine = pathname.includes(__('cucine'));
        const isCamerette = pathname.includes(__('camerette'));

        // Task #260: Restituisco l'url del secondo livello se non mi trovo nelle seguenti categorie e sottocategorie
        if (secondolivello === 1 && !(isDivani || isLetti || isBagni || isSedie || isTavoli || isSoggiorni || isMaterassi || isCamere || isCucine || isCamerette)) {
            return this.renderSecondaryLink();
        }

        // Nelle categorie considerate restituisco l'url del dettaglio prodotto
        return (
            <Link
              { ...this.containerFunctions }
              { ...this.containerProps() }
              to={ url }
            >
                { children }
            </Link>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryLinkContainer);
