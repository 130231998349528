import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import FlashSaleIndicator from 'Component/FlashSalesIndicator';
import ProductActionButton from 'Component/ProductCardDetails/Elements/ProductActionButton.component';
import ProductCertification from 'Component/ProductCardDetails/Elements/ProductCertification.component';
import ProductComposizioneTipo from 'Component/ProductCardDetails/Elements/ProductComposizioneTipo.component';
import ProductDescription from 'Component/ProductCardDetails/Elements/ProductDescription.component';
import ProductDimensions from 'Component/ProductCardDetails/Elements/ProductDimensions.component';
import ProductFinish from 'Component/ProductCardDetails/Elements/ProductFinish.component';
import ProductLink from 'Component/ProductCardDetails/Elements/ProductLink.component';
import ProductName from 'Component/ProductCardDetails/Elements/ProductName.component';
import { ProductType } from 'Type/ProductList.type';

/** @namespace Pwa/Component/ProductCardDetails/Layout/PromoClosed/Component */
export class PromoClosedComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        promo: PropTypes.string.isRequired,
    };

    render() {
        const {
            product,
            product: {
                sku,
                mc_color_information: { description },
                mc_flashsale,
            },
            promo,
        } = this.props;

        return (
            <ProductLink
              product={ product }
              mix={ {
                  block: 'ProductCardDetails',
                  mods: {
                      single: true, column: true, promo: true, [promo]: true,
                  },
              } }
            >
                <div block="ProductCardDetails" elem="ContentWrapper">
                    <div block="ProductCardDetails" elem="Content">
                        <div block="ProductCardDetails" elem="MainInfo">
                            <ProductName product={ product } />

                            { description?.trim() && (
                                <>
                                <ProductDescription product={ product } />
                                <ProductFinish product={ product } />
                                </>
                            ) }
                            { !description?.trim() && (
                                <>
                                <ProductFinish product={ product } />
                                <ProductDescription product={ product } />
                                </>
                            ) }

                        </div>

                        <div block="ProductCardDetails" elem="FeaturesList" />

                        <div block="ProductCardDetails" elem="PriceContainer">
                            <FlashSaleIndicator
                              flashsale={ mc_flashsale }
                              sku={ sku }
                              isInfoIcon={ false }
                            />
                            <ProductDimensions product={ product } />
                            <ProductComposizioneTipo product={ product } />
                        </div>
                    </div>
                    <div block="ProductCardDetails" elem="PromoActionWrapper">
                        <div>
                            <ProductCertification product={ product } size="small" />
                        </div>
                        <ProductActionButton
                          product={ product }
                          mix={ { block: 'ProductCardDetails', elem: 'PromoAction', mods: { isClosed: true } } }
                          isDisplayMore
                        />
                    </div>
                </div>
            </ProductLink>
        );
    }
}

export default PromoClosedComponent;
