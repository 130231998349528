import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ONE_COLUMN_LAYOUT, THREE_COLUMN_LAYOUT, TWO_COLUMN_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { ProductType } from 'Type/ProductList.type';
import { countdown } from 'Util/Countdown';

import ProductCardDetails from './ProductCardDetails.component';

/** @namespace Pwa/Component/ProductCardDetails/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    promoBF: state.BlackFridayCounterPromoReducer.promo,
});

/** @namespace Pwa/Component/ProductCardDetails/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Pwa/Component/ProductCardDetails/Container */
export class ProductCardDetailsContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        colorChanger: PropTypes.func.isRequired,
        promo: PropTypes.string,
        layout: PropTypes.oneOf([
            ONE_COLUMN_LAYOUT,
            TWO_COLUMN_LAYOUT,
            THREE_COLUMN_LAYOUT,
        ]),
    };

    static defaultProps = {
        layout: ONE_COLUMN_LAYOUT,
        promo: null,
    };

    state = {
        countdown: {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        },
    };

    containerFunctions = {
        // convertProductPrice: this.convertProductPrice.bind(this)
    };

    componentDidMount() {
        const {
            product: {
                mc_black_friday_counter: { time_end },
            },
        } = this.props;

        if (time_end !== null) {
            this.carouselInterval = countdown(time_end, (countdown) => this.setState({ countdown }));
        }
    }

    componentWillUnmount() {
        clearTimeout(this.carouselInterval);
    }

    _getProduct = () => {
        const { product } = this.props;

        return product;
    };

    containerProps = () => {
        const { colorChanger, layout, promo } = this.props;

        return {
            colorChanger,
            layout,
            promo,
            product: this._getProduct(),
        };
    };

    render() {
        const { countdown } = this.state;

        return (
            <ProductCardDetails
              { ...this.containerFunctions }
              { ...this.containerProps() }
              countdown={ countdown }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardDetailsContainer);
