import { PureComponent } from 'react';

import TextPlaceholder from 'Component/TextPlaceholder';
import { ProductType } from 'Type/ProductList.type';

/** @namespace Pwa/Component/ProductCardDetails/Elements/ProductDescription/Component */
export class ProductDescriptionComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
    };

    render() {
        const { product: { mc_inline_description } } = this.props;

        return (
            <div block="ProductCardDetails" elem="InlineDescription" mods={ { isLoaded: !!mc_inline_description } }>
                <TextPlaceholder content={ mc_inline_description } length="medium" />
            </div>
        );
    }
}

export default ProductDescriptionComponent;
