import { PureComponent } from 'react';

import { ProductType } from 'Type/ProductList.type';

/** @namespace Pwa/Component/ProductCardDetails/Elements/ProductFinish/Component */
export class ProductFinishComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
    };

    render() {
        const { product: { mc_color_information } } = this.props;

        if (mc_color_information?.description) {
            return (
                <div block="ProductCardDetails" elem="Finish">
                    <span>{ mc_color_information?.description }</span>
                </div>
            );
        }

        return null;
    }
}

export default ProductFinishComponent;
