import { PureComponent } from 'react';

import Link from 'Component/Link';
import NoPropagationEvent from 'Component/NoPropagateEvents';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ProductType } from 'Type/ProductList.type';

/** @namespace Pwa/Component/ProductCardDetails/Elements/ProductName/Component */
export class ProductNameComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
    };

    render() {
        const { product: { name, url = '' } } = this.props;

        return (
          <NoPropagationEvent>

          <Link
            block="ProductCardDetails"
            elem="Link"
            to={ url }
          >
            <p
              block="ProductCardDetails"
              elem="Name"
              mods={ { isLoaded: !!name } }
            >
                <TextPlaceholder content={ name } length="medium" />
            </p>
          </Link>
          </NoPropagationEvent>

        );
    }
}

export default ProductNameComponent;
